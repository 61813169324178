import React from "react";
export const WhoAreWe = ({who_are_we}) => {
    return (
      <div id="whoarewe" className='mt-20 lg:flex-row flex  flex-col  px-4 justify-center lg:items-center   bg-[#FAFAFA] container'>
      <div className='lg:w-6/12 w-12/12  2xl:w-6/12   '>
          <h1 className='lg:text-7xl  text-5xl font-semibold'> {who_are_we.Title.blackText} <span className='text-orange'>{who_are_we.Title.orangeText}</span>  </h1>
          <p className='text-orange lg:text-2xl text-lg mt-5 mb-1 font-medium'>{who_are_we.subTitle}</p>
          <p className='text-sm lg:text-xl text-start mb-4  leading-8'>{who_are_we.description}</p>
      </div >
      <div  className='lg:w-5/12 w-12/12  flex  justify-center rounded-2xl '>
          <img src={process.env.REACT_APP_BASE_URL+who_are_we.image.data.attributes.url}></img></div>
      </div>
    )

  
};
