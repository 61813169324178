import React, { useState } from 'react';
import { GenericHeader } from '../Components/MainComponents/GenericHeader';
import { AboutProject } from '../Components/MainComponents/ProjectPage/AboutProject';
import { useFetchContent } from '../Utils/CustomHooks/useFetchContent';
import { Loader } from '../Components/Utilities/Loader';
import { AllProjects } from '../Components/MainComponents/Projects/AllProjects';
import Popup from 'reactjs-popup';
import Cookies from "js-cookie";
import { Link } from 'react-router-dom';
import { ProjectsCarousel } from '../Components/MainComponents/Projects/ProjectsCarousel';

export const ProjectPage = () => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const id = params.get('id');
  const [projID, setprojID] = useState(id)

  const { isPending, error, data } = useFetchContent(`/projects/${id}?populate=deep`, 'projectDetails');
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [currentCategoryImages, setCurrentCategoryImages] = useState([]);
  const [menuModalOpen, setMenuModalOpen] = useState(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [modalSource, setModalSource] = useState(null); // 'imageClick' or 'menu'

  if (isPending) return <Loader />;
  if (error) return 'An error has occurred: ' + error.message;
  
  let {
    name,
    description,
    RelatedProject,
    Client,
    Status,
    area,
    location,
    cost,
    type,
    consultant,
    Header,
    blogText,
    ImageCatalog,
  } = data.data.attributes;

  const openModal = (imgIndex, images, source) => {
    setSelectedImageIndex(imgIndex);
    setCurrentCategoryImages(images);
    setModalSource(source);
  };

  const closeModal = () => {
    setSelectedImageIndex(null);
    setCurrentCategoryImages([]);
    setModalSource(null);
  };

  const openMenuModal = (tabIndex) => {
    setSelectedTabIndex(tabIndex);
    setMenuModalOpen(true);
  };

  const closeMenuModal = () => {
    setMenuModalOpen(false);
  };

  const handleNextImage = () => {
    const imageCount = modalSource === 'menu' ? currentCategoryImages.length : 4;
    setSelectedImageIndex((prevIndex) => (prevIndex + 1) % imageCount);
  };

  const handlePrevImage = () => {
    const imageCount = modalSource === 'menu' ? currentCategoryImages.length : 4;
    setSelectedImageIndex((prevIndex) => (prevIndex - 1 + imageCount) % imageCount);
  };

  return (
    <>
      <GenericHeader
        Header={Header}
        headerType={'abtHeader'}
        title={'About Us'}
        subtitle={'Home / About Us'}
      />
      <AboutProject
        name={name}
        description={description}
        area={area}
        Client={Client}
        Status={Status}
        location={location}
        blogText={blogText}
        cost={cost}
        type={type}
        consultant={consultant}
      />

      {ImageCatalog.ImageCategory.map((imgCat, idx) => (
        <div className="container mx-auto my-6 px-4" key={imgCat.Title}>
          <div className="flex flex-wrap justify-between items-center mb-4">
            <h1 className="text-lg sm:text-xl md:text-3xl font-semibold">{imgCat.Title}</h1>
            <button
              className="text-orange text-sm md:text-base"
              onClick={() => openMenuModal(idx)}
            >
              {Cookies.get("lang") === 'ar' ? "المزيد" : "See More"}
            </button>
          </div>
          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
            {imgCat.Images.data.map((img, indx) =>
              indx < 4 ? (
                <img
                  key={indx}
                  className="w-full h-auto rounded-md transition-transform duration-300 ease-in-out hover:scale-105 cursor-pointer"
                  src={process.env.REACT_APP_BASE_URL + img.attributes.url}
                  alt={`Image ${indx + 1}`}
                  onClick={() => openModal(indx, imgCat.Images.data, 'imageClick')}
                />
              ) : null
            )}
          </div>
        </div>
      ))}

      <Popup
        open={menuModalOpen}
        onClose={closeMenuModal}
        modal
        closeOnDocumentClick={false}
        contentStyle={{
          width: '90%',
          maxWidth: '1200px',
          padding: '20px',
          borderRadius: '10px',
          overflowY: 'auto',
          background: 'white',
          maxHeight: '80vh',
        }}
        overlayStyle={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          background: 'rgba(0, 0, 0, 0.6)',
        }}
      >
        <div>
          <button
            className="absolute top-4 right-4 bg-transparent text-gray-600 hover:text-gray-800"
            onClick={closeMenuModal}
          >
            <svg
              className="w-6 h-6 text-gray-800 dark:text-black"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18 17.94 6M18 18 6.06 6"
              />
            </svg>
          </button>
          <div className="flex flex-wrap justify-center gap-2">
            {ImageCatalog.ImageCategory.map((imgCat, idx) => (
              <button
                key={idx}
                className={`px-3 py-2 rounded-md ${
                  selectedTabIndex === idx ? 'bg-orange text-white' : 'bg-gray-100 text-gray-600'
                }`}
                onClick={() => setSelectedTabIndex(idx)}
              >
                {imgCat.Title}
              </button>
            ))}
          </div>
          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4 mt-4">
            {ImageCatalog.ImageCategory[selectedTabIndex].Images.data.map((img, idx) => (
              <img
                key={idx}
                className="w-full h-auto rounded-md cursor-pointer"
                src={process.env.REACT_APP_BASE_URL + img.attributes.url}
                alt={`Image ${idx + 1}`}
                onClick={() =>
                  openModal(idx, ImageCatalog.ImageCategory[selectedTabIndex].Images.data, 'menu')
                }
              />
            ))}
          </div>
        </div>
      </Popup>

      {selectedImageIndex !== null && (
        <Popup
          open={selectedImageIndex !== null}
          onClose={closeModal}
          modal
          closeOnDocumentClick
          contentStyle={{
            width: '90%',
            maxWidth: '1000px',
            padding: '20px',
            borderRadius: '10px',
            background: 'white',
          }}
          overlayStyle={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: 'rgba(0, 0, 0, 0.6)',
          }}
        >
          <div className="relative">
            <button
              onClick={handlePrevImage}
              className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-gray-200 p-2 rounded-md hover:bg-gray-300"
            >
              <svg
                className="w-10 h-10 text-gray-800 dark:text-white"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m15 19-7-7 7-7"
                />
              </svg>
            </button>
            <img
              src={
                process.env.REACT_APP_BASE_URL +
                currentCategoryImages[selectedImageIndex].attributes.url
              }
              alt={`Image ${selectedImageIndex + 1}`}
              className="w-full h-auto rounded-md"
            />
            <button
              onClick={handleNextImage}
              className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-gray-200 p-2 rounded-md hover:bg-gray-300"
            >
              <svg
                className="w-10 h-10 text-gray-800 dark:text-white"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m9 5 7 7-7 7"
                />
              </svg>
            </button>
          </div>
          <button
            className="absolute top-7 right-7 bg-transparent text-gray-600 hover:text-gray-800"
            onClick={closeModal}
          >
            <svg
              className="w-10 h-10 text-gray-800 dark:text-white"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18 17.94 6M18 18 6.06 6"
              />
            </svg>
          </button>
        </Popup>
      )}

      <div className="container mx-auto px-4">
        <p className="text-lg sm:text-2xl md:text-4xl font-semibold mt-12 mb-8">
          Related Projects
        </p>
        <ProjectsCarousel projects={RelatedProject.projects}></ProjectsCarousel>
   
      </div>
      
    </>
  );
};
