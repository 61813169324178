import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';

export const ProjectsCarousel = ({ projects }) => {
  // Set the number of projects per page (3 per row)
  const projectsPerPage = 3;

  // Use state to track the current page index
  const [currentPage, setCurrentPage] = useState(0);

  // Calculate the total number of pages (groups of 3 projects)
  const totalPages = Math.ceil(projects.data.length / projectsPerPage);

  // Get the current slice of projects to display
  const currentProjects = projects.data.slice(currentPage * projectsPerPage, (currentPage + 1) * projectsPerPage);

  // Handle "Next" button click
  const nextPage = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Handle "Previous" button click
  const prevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <>
      <div className="carousel-container">
        <div className="grid md:grid-cols-3 grid-cols-1 auto-rows-[20rem] gap-4">
          {currentProjects.map((card, i) => (
            <main key={card.id} className={`col-span-1`}>
              <Link to={`/project?id=${card.id}`} className='w-full h-full' onClick={()=>{setTimeout(() => {window.location.reload();}, 1)}}>
                <div className="card1 text-white w-full h-full">
                  <img
                    src={`${process.env.REACT_APP_BASE_URL + card.attributes.images.data[0].attributes.url}`}
                    className=""
                    alt=""
                  />
                  <div className="">
                    <h2
                      className={`text-xl uppercase ${Cookies.get("lang") === "ar" ? "ar" : null}`}
                    >
                      {card.attributes.name + `${Cookies.get("lang") === "ar" ? " ← " : " → "}`}
                    </h2>
                   
                  </div>
                </div>
              </Link>
            </main>
          ))}
        </div>

        {/* Carousel Controls */}
        <div className="flex justify-between mt-4">
          <button onClick={prevPage} disabled={currentPage === 0} className="btn-prev">
         
{Cookies.get("lang")=="ar"?   <svg class="w-10 h-10 text-gray-800 dark:text-black" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 12H5m14 0-4 4m4-4-4-4"/>
</svg>: <svg class="w-10 h-10 text-gray-800 dark:text-black" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 12h14M5 12l4-4m-4 4 4 4"/>
</svg>}
          </button>
          <button onClick={nextPage} disabled={currentPage === totalPages - 1} className="btn-next">
          {Cookies.get("lang")=="ar"?   <svg class="w-10 h-10 text-gray-800 dark:text-black" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 12h14M5 12l4-4m-4 4 4 4"/>
</svg> : <svg class="w-10 h-10 text-gray-800 dark:text-black" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 12H5m14 0-4 4m4-4-4-4"/>
</svg>}
          </button>
        </div>
      </div>
    </>
  );
}
