import React from 'react'

export const GenericHeader = ({headerType, Header, title, subtitle}) => {
  const imageUrl = Header?.image?.data?.attributes
    ? process.env.REACT_APP_BASE_URL + Header.image.data.attributes.url
    : "/Assets/Images/Headers/HSHeader.png"; // Fallback image URL

  return (
    <div 
      className="flex flex-col items-center justify-center text-center bg-cover bg-no-repeat bg-center" 
      style={{ backgroundImage: `url(${imageUrl})`, height: '360px'}}
    >
      <h1 className="text-white font-bold text-5xl">{Header.title ? Header.title : title }</h1>
      <p className="text-white mt-4">{Header.subTitle ? Header.subTitle : subtitle}</p>
    </div>
  )
}
