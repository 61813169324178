import React from "react";
import Cookies from "js-cookie";

export const AboutProject = ({
  Client,
  Status,
  description,
  name,
  area,
  location,
  cost,
  type,
  consultant,
  blogText,
}) => {
  return (
    <>
      <div className="bg-white py-20 mt-20">
        {/* Title Section */}
        <div className="container flex justify-center items-center 2xl:w-9/12">
          <h1 className="text-4xl uppercase font-medium text-center">{name}</h1>
        </div>

        {/* Blog Text Section */}
        <div className="container mt-3 leading-8 text-center 2xl:w-9/12">
          <p>{blogText}</p>
        </div>

        {/* Details Section */}
        <div className="container  mt-12 2xl:w-9/12">
          <div className="flex ml-4 md:ml-0 flex-wrap justify-between">
            {/* Row 1 */}
            <div className="w-full md:w-1/2 flex items-center mb-6">
              <p className="text-base font-bold">
                {Cookies.get("lang") === "ar" ? "العميل: " : "Client: "}
              </p>
              <p className="ml-2 text-base">{Client}</p>
            </div>
            <div className="w-full md:w-1/2 flex items-center mb-6">
              <p className="text-base font-bold">
                {Cookies.get("lang") === "ar" ? "المستشار: " : "Consultant: "}
              </p>
              <p className="ml-2 text-base">{consultant?.name}</p>
            </div>

            {/* Row 2 */}
            <div className="w-full md:w-1/2 flex items-center mb-6">
              <p className="text-base font-bold">
                {Cookies.get("lang") === "ar" ? "المساحة: " : "BUA: "}
              </p>
              <p className="ml-2 text-base">{area} Sqm</p>
            </div>
            <div className="w-full md:w-1/2 flex items-center mb-6">
              <p className="text-base font-bold">
                {Cookies.get("lang") === "ar" ? "الموقع: " : "Location: "}
              </p>
              <p className="ml-2 text-base">{location}</p>
            </div>

            {/* Row 3 */}
            <div className="w-full md:w-1/2 flex items-center mb-6">
              <p className="text-base font-bold">
                {Cookies.get("lang") === "ar" ? "التكلفة: " : "Cost: "}
              </p>
              <p className="ml-2 text-base">{cost}</p>
            </div>
            <div className="w-full md:w-1/2 flex items-center mb-6">
              <p className="text-base font-bold">
                {Cookies.get("lang") === "ar" ? "الحالة: " : "Status: "}
              </p>
              <p className="ml-2 text-base">{Status}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
